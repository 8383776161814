// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-contact-banner-js": () => import("./../../../src/pages/landing/contact-banner.js" /* webpackChunkName: "component---src-pages-landing-contact-banner-js" */),
  "component---src-pages-landing-lp-overview-js": () => import("./../../../src/pages/landing/lp-overview.js" /* webpackChunkName: "component---src-pages-landing-lp-overview-js" */),
  "component---src-pages-roadmap-index-js": () => import("./../../../src/pages/roadmap/index.js" /* webpackChunkName: "component---src-pages-roadmap-index-js" */),
  "component---src-pages-solutions-project-phoenix-index-js": () => import("./../../../src/pages/solutions/project-phoenix/index.js" /* webpackChunkName: "component---src-pages-solutions-project-phoenix-index-js" */),
  "component---src-pages-support-us-email-signup-form-js": () => import("./../../../src/pages/support-us/email-signup-form.js" /* webpackChunkName: "component---src-pages-support-us-email-signup-form-js" */),
  "component---src-pages-support-us-index-js": () => import("./../../../src/pages/support-us/index.js" /* webpackChunkName: "component---src-pages-support-us-index-js" */)
}

